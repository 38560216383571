:root {
  --primary-color: #fff;
  --primary-color2: #0a0f29;
  --primary-color3: #3a67b1;
  --primary-color4: #0a0f29;
  --primary-color5: #f8f8f8;
  --primary-color6: #0a0f29;
  --primary-color7: #f8f8f8;
  --bg-section2: #f8f8f8;
  --primary-color8: #3a67b1;
  --primary-color9: #3a67b1;

  --default-light-banner-url: url(../images/default-banner.webp);
  --profile-light-banner-url: url(../images/profile-banner.webp);
}

@font-face {
  font-family: 'OpenSauceSans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/OpenSauceSans-400.woff2) format('woff2');
}

@font-face {
  font-family: 'OpenSauceSans';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url(../fonts/OpenSauceSans-bold.woff2) format('woff2');
}

.heading {
  margin-top: 60px !important;
}

body,
button,
input,
select,
textarea {
  font-family: 'OpenSauceSans' !important;
}

.flat-title-page.inner {
  padding: 200px 0;
}

#footer {
  background-color: var(--primary-color6) !important;
}

#footer h5,
#footer .mg-t-20,
.widget-menu ul li a {
  color: #fff !important;
}

.widget-social.style-1 ul li a,
.menu_card .dropdown-menu a {
  color: var(--primary-color6) !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus {
  background-color: var(--primary-color3) !important;
  border-color: var(--primary-color3) !important;
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus {
  border: 2px solid #fff !important;
}

/* Searchbox */
input[type='search'] {
  border: thin solid #fff !important;
}

@media (max-width: 992px) {
  input[type='search'] {
    border: thin solid var(--primary-color9) !important;
  }

  input[type='search']:focus {
    border: thin solid var(--primary-color9) !important;
  }

  input[type='search']::placeholder {
    color: var(--primary-color9) !important;
  }

  .header-search-form-container button {
    color: var(--primary-color9) !important;
  }
}

/* Banner */

.mainslider .swiper-button-prev,
.mainslider .swiper-button-next {
  background: var(--primary-color4) !important;
}

.header_1.header_2.style2 {
  background: rgba(10, 15, 41, 0.85) !important;
}

.wrap-cart .cart_item.style2 p {
  text-shadow: 2px 2px var(--primary-color4) !important;
}

/* Index banner image */
.flat-cart-item .overlay {
  background-size: cover !important;
  background-image: url(../images/index-banner.webp) !important;
}

/* Collection banner image */
#\/collection-banner {
  background: url(../images/collection-banner.webp) center center no-repeat !important;
  background-size: cover !important;
}

/* Launchpad banner image */
#\/launchpad-banner {
  background: url(../images/launchpad-banner.webp) center center no-repeat !important;
  background-size: cover !important;
}

/* FAQ banner image */
#\/faq-banner {
  background: url(../images/faq-banner.webp) center center no-repeat !important;
  background-size: cover !important;
}
